import styles from "./../styles.module.css";
import "./../agreement.css";

import React, { Component, Fragment } from "react";
import axios from "axios";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { HTML5_FMT } from "moment";
import SignatureCanvas from "react-signature-canvas";
import {
  ValidateEmail,
  normalize,
  ValidateDomain,
  axiosConfig,
} from "../../../config/utility";
import { APP_LIVE_URL } from "../../../config/config";

import { registerProfileInformation } from "../../../config/client_api_calls";

import { Helmet, HelmetProvider } from "react-helmet-async";

import MyCustomTextField from "../../../components/InputFields/TextField";
import MyCustomRadioField from "../../../components/InputFields/RadioField";
import MyCustomCheckBoxField from "../../../components/InputFields/CheckBox";
import MyCustomButton from "../../../components/InputFields/Button";
import MyCustomDatePicker from "../../../components/InputFields/BasicDatePicker";

import "../ClientRegister/registration_step.css";

export class Step1 extends Component {
  continue = (data) => {
    //e.preventDefault();
    this.props.nextStep(data);
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      signature_data: "",
      disabled:
        props.jsonData.step_1.sub_steps[0].button_fields.continue.disabled,
    };
  }

  componentDidMount() {
    const { keys, values } = this.props.values;
    const { jsonData } = this.props;
    console.log("componentDidMount", values);
    let authority_to_sign = false;
    if (values[keys.signature_data] && values[keys.signature_data] !== "") {
      authority_to_sign = true;
    }
    this.setState({
      [keys.firstname]: values[keys.firstname],
      [keys.lastname]: values[keys.lastname],
      [keys.email]: values[keys.email],
      [keys.phone]: values[keys.phone],
      [keys.date_of_birth]: values[keys.date_of_birth],
      [keys.telegram_username]: values[keys.telegram_username],
      [keys.linkedin]: values[keys.linkedin],
      [keys.business_owner]:
        values[keys.business_owner] === "yes"
          ? true
          : values[keys.business_owner] === "no"
            ? false
            : values[keys.business_owner],
      [keys.position]:
        values[keys.business_owner] === "yes" ? "" : values[keys.position],
      [keys.authority_to_sign]: authority_to_sign,
      [keys.signature_data]: values[keys.signature_data],
    });
  }

  submitProfileData = async (profileData) => {
    const { signup_hash, steps_completed, steps_allowed } = this.props;
    //console.log('signup_hash',signup_hash);
    if (signup_hash !== "") {
      const { keys } = this.props.values;
      try {
        let response = await registerProfileInformation({
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          signup_hash: signup_hash,
          firstname: profileData.values[keys.firstname],
          lastname: profileData.values[keys.lastname],
          email: profileData.values[keys.email],
          phone: profileData.values[keys.phone],
          date_of_birth: profileData.values[keys.date_of_birth],
          telegram_username: profileData.values[keys.telegram_username],
          linkedin: profileData.values[keys.linkedin],
          business_owner: profileData.values[keys.business_owner],
          position: profileData.values[keys.position],
          signature_data: profileData.values[keys.signature_data],
        });
        console.log("registerProfileInformation", response.data);
        if (response.data.status === 404 || response.data.status === 422) {
          this.setState({
            errorMessage: "Something went wrong. Please try again",
            successMessage: "",
            disabled: false,
          });
        } else if (
          response.data.status === 200 &&
          response.data.message === "success"
        ) {
          if (steps_completed === steps_allowed - 1) {
            // this.setState({
            //   errorMessage: '',
            //   successMessage: "Successfully submitted data.",
            //   disabled: true,
            // });
            this.props.successHandler();
          } else {
            this.continue(profileData);
          }
        }
      } catch (err) { }
    }
  };

  SubmitFirst = (event) => {
    event.preventDefault();
    const { jsonData } = this.props;
    const { keys } = this.props.values;
    //console.log('event',event);

    let firstname_json = {
      required: jsonData.step_1.sub_steps[0].input_fields.firstname.required,
      visible: jsonData.step_1.sub_steps[0].input_fields.firstname.visible,
    };
    let lastname_json = {
      required: jsonData.step_1.sub_steps[0].input_fields.lastname.required,
      visible: jsonData.step_1.sub_steps[0].input_fields.lastname.visible,
    };
    let email_json = {
      required: jsonData.step_1.sub_steps[0].input_fields.email.required,
      visible: jsonData.step_1.sub_steps[0].input_fields.email.visible,
    };
    let phone_json = {
      required: jsonData.step_1.sub_steps[0].input_fields.phone.required,
      visible: jsonData.step_1.sub_steps[0].input_fields.phone.visible,
    };
    let date_of_birth_json = {
      required:
        jsonData.step_1.sub_steps[0].input_fields.date_of_birth.required,
      visible: jsonData.step_1.sub_steps[0].input_fields.date_of_birth.visible,
    };
    let telegram_username_json = {
      required:
        jsonData.step_1.sub_steps[0].input_fields.telegram_username.required,
      visible:
        jsonData.step_1.sub_steps[0].input_fields.telegram_username.visible,
    };
    let linkedin_json = {
      required: jsonData.step_1.sub_steps[0].input_fields.linkedin.required,
      visible: jsonData.step_1.sub_steps[0].input_fields.linkedin.visible,
    };
    let business_owner_json = {
      visible:
        jsonData.step_1.sub_steps[0].checkbox_fields.business_owner.visible,
      required:
        jsonData.step_1.sub_steps[0].checkbox_fields.business_owner.required,
    };
    let position_json = {
      required: jsonData.step_1.sub_steps[0].input_fields.position.required,
      visible: jsonData.step_1.sub_steps[0].input_fields.position.visible,
    };
    let signatury_authority_json = {
      visible:
        jsonData.step_1.sub_steps[0].checkbox_fields.signatury_authority
          .visible,
      required:
        jsonData.step_1.sub_steps[0].checkbox_fields.signatury_authority
          .required,
    };

    let isValidated = true;

    if (
      firstname_json.visible &&
      firstname_json.required &&
      this.state[keys.firstname] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please enter your first name.",
      });
    } else if (
      lastname_json.visible &&
      lastname_json.required &&
      this.state[keys.lastname] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please enter your last name.",
      });
    } else if (
      email_json.visible &&
      email_json.required &&
      this.state[keys.email] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please enter your email.",
      });
    } else if (
      email_json.visible &&
      email_json.required &&
      ValidateEmail(this.state[keys.email]) === false
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Invalid email format.",
      });
    } else if (
      phone_json.visible &&
      phone_json.required &&
      this.state[keys.phone] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please enter your phone.",
      });
    } else if (
      phone_json.visible &&
      phone_json.required &&
      this.state[keys.phone].length < 10
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Phone no is invalid.",
      });
    } else if (
      date_of_birth_json.visible &&
      date_of_birth_json.required &&
      this.state[keys.date_of_birth_json] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please enter date of birth.",
      });
    } else if (
      telegram_username_json.visible &&
      telegram_username_json.required &&
      this.state[keys.telegram_username_json] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please enter telegram username",
      });
    } else if (
      linkedin_json.visible &&
      linkedin_json.required &&
      this.state[keys.linkedin] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please enter your linkedin url",
      });
    } else if (
      business_owner_json.visible &&
      business_owner_json.required &&
      this.state[keys.business_owner] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please select the buisness owner",
      });
    } else if (
      business_owner_json.visible &&
      business_owner_json.required &&
      this.state[keys.business_owner] === false &&
      position_json.visible &&
      position_json.required &&
      this.state[keys.position] === ""
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please select your position",
      });
    } else if (
      signatury_authority_json.visible &&
      signatury_authority_json.required &&
      !this.state[keys.authority_to_sign]
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Authority to sign is required",
        signature_data: "",
      });
    } else if (
      signatury_authority_json.visible &&
      signatury_authority_json.required &&
      this.state[keys.authority_to_sign] &&
      this.state[keys.signature_data] === "" &&
      this.signaturePad.isEmpty()
    ) {
      isValidated = false;
      this.setState({
        errorMessage: "Please enter your signature",
      });
    } else {
      this.setState({ errorMessage: "", successMessage: "", disabled: true });
      let date_of_birth = this.state[keys.date_of_birth];

      let sigdata = this.state[keys.signature_data];
      if (this.state[keys.signature_data] === "") {
        sigdata = this.signaturePad.getTrimmedCanvas().toDataURL("image/svg+xml");
      }

      const step1_json = {
        keys: keys,
        values: {
          [keys.firstname]: this.state[keys.firstname],
          [keys.lastname]: this.state[keys.lastname],
          [keys.email]: this.state[keys.email],
          [keys.phone]: this.state[keys.phone],
          [keys.date_of_birth]: date_of_birth.includes("T")
            ? this.state[keys.date_of_birth].split("T")[0]
            : this.state[keys.date_of_birth],
          [keys.telegram_username]: this.state[keys.telegram_username],
          [keys.linkedin]: this.state[keys.linkedin],
          [keys.business_owner]: this.state[keys.business_owner],
          [keys.position]: this.state[keys.position],
          [keys.authority_to_sign]: this.state[keys.authority_to_sign],
          [keys.signature_data]: sigdata,
        },
      };
      console.log("step_1_data", step1_json);
      this.submitProfileData(step1_json);

      //this.continue(step1_json);
    }
    if (!isValidated) {
      setTimeout(() => {
        this.setState({
          errorMessage: "",
          successMessage: "",
          disabled: false,
        });
      }, 3000);
    }
  };

  clear = (e) => { };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    //this.signaturePad.scrollIntoView({ behavior: "smooth" });

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  render() {
    const {
      jsonData,
      mStepsUITitles,
      mStepsUIDesc,
      steps_completed,
      steps_allowed,
    } = this.props;
    const { values, keys } = this.props.values;
    //console.log('constructor step 1',this.props.values.business_owner);
    //console.log('render',this.state[keys.business_owner]);

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init nk-stepper-s1"
              action="#"
              onSubmit={this.SubmitFirst}
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner" id="registration_div">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      <li
                        className={steps_completed === 0 ? "current" : "done"}
                      >
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">{mStepsUITitles[0]}</div>
                            <div className="sub-text">{mStepsUIDesc[0]}</div>
                          </div>
                        </div>
                      </li>
                      {steps_allowed >= 2 ? (
                        <li
                          className={
                            steps_completed === 1
                              ? "current"
                              : steps_completed > 1 && steps_completed <= 3
                                ? "done"
                                : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[1]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[1]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 3 ? (
                        <li
                          className={
                            steps_completed === 2
                              ? "current"
                              : steps_completed > 2 && steps_completed <= 4
                                ? "done"
                                : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[2]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[2]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 4 ? (
                        <li
                          className={
                            steps_completed === 3
                              ? "current"
                              : steps_completed > 3 && steps_completed <= 5
                                ? "done"
                                : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[3]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[3]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 5 ? (
                        <li
                          className={
                            steps_completed === 4
                              ? "current"
                              : steps_completed > 4 && steps_completed <= 6
                                ? "done"
                                : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[4]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[4]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 6 ? (
                        <li
                          className={
                            steps_completed === 5
                              ? "current"
                              : steps_completed > 5 && steps_completed <= 7
                                ? "done"
                                : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[5]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[5]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 7 ? (
                        <li
                          className={
                            steps_completed === 6
                              ? "current"
                              : steps_completed > 6 && steps_completed <= 8
                                ? "done"
                                : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[6]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[6]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 8 ? (
                        <li
                          className={
                            steps_completed === 7
                              ? "current"
                              : steps_completed > 7 && steps_completed <= 9
                                ? "done"
                                : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[7]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[7]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {/* <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview Details</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step">
                          <h4
                            className="title mb-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {jsonData.step_1.sub_steps[0].title}
                          </h4>
                          <p
                            style={{
                              textAlign: "center",
                              marginLeft: "10%",
                              marginRight: "10%",
                            }}
                          >
                            {jsonData.step_1.sub_steps[0].description}
                          </p>
                          <div className="row g-3">
                            {jsonData.step_1.sub_steps[0].input_fields.firstname
                              .visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_1.sub_steps[0].input_fields
                                        .firstname
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.firstname]}`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].input_fields.lastname
                              .visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_1.sub_steps[0].input_fields
                                        .lastname
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.lastname]}`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].input_fields.email
                              .visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_1.sub_steps[0].input_fields
                                        .email
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.email]}`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].input_fields.phone
                              .visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  {/* <label
                                  className="form-label"
                                  htmlFor="fv-phone"
                                >
                                  Phone
                                </label>
                                <div className="form-control-wrap">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="fv-phone"
                                      >
                                        +1
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      inputprops={{ maxLength: 14 }}
                                      defaultValue={values.phone}
                                      onChange={handleChange}
                                      name="phone"
                                      id="phone"
                                      maxLength="14"
                                      placeholder="2121111111"
                                      required=""
                                    />
                                  </div>
                                </div> */}
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_1.sub_steps[0].input_fields
                                        .phone
                                    }
                                    onChange={this.handleChangeCheck}
                                    value={`${values[keys.phone]}`}
                                    type="text"
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].input_fields
                              .date_of_birth.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomDatePicker
                                    data={
                                      jsonData.step_1.sub_steps[0].input_fields
                                        .date_of_birth
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.date_of_birth]
                                      }`}
                                    value={`${values[keys.date_of_birth]}`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].input_fields
                              .telegram_username.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_1.sub_steps[0].input_fields
                                        .telegram_username
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.telegram_username]
                                      }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].input_fields.linkedin
                              .visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_1.sub_steps[0].input_fields
                                        .linkedin
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.linkedin]}`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].checkbox_fields
                              .business_owner.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {jsonData.step_1.sub_steps[0].checkbox_fields.business_owner.data.map(
                                      (result, index) => (
                                        <li key={`business_owner${index}`}>
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            checked={
                                              this.state[keys.business_owner]
                                            }
                                          />
                                          <span className="ms-2" />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {/* {jsonData.step_1.sub_steps[0].radio_fields
                              .business_owner.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <p>
                                    {
                                      jsonData.step_1.sub_steps[0].radio_fields
                                        .business_owner.label
                                    }
                                  </p>
                                  <ul className="custom-control-group custom-control-horizontal custom-control-stacked w-100">
                                    {jsonData.step_1.sub_steps[0].radio_fields.business_owner.data.map(
                                      (result, index) => (
                                        <li key={`business_owner${index}`}>
                                          <MyCustomRadioField
                                            data={result}
                                            onChange={this.handleChangeCheck}  
                                            checked={this.state[keys.business_owner] === result.id ? true : false}                                                                                
                                          />
                                          <span className="ms-2" />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null} */}
                            {jsonData.step_1.sub_steps[0].input_fields.position
                              .visible &&
                              this.state[keys.business_owner] === false ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_1.sub_steps[0].input_fields
                                        .position
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={
                                      values[keys.business_owner] === "yes"
                                        ? ""
                                        : `${values[keys.position]}`
                                    }
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].checkbox_fields
                              .signatury_authority.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {jsonData.step_1.sub_steps[0].checkbox_fields.signatury_authority.data.map(
                                      (result, index) => (
                                        <li key={`signatury_authority${index}`}>
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            checked={
                                              this.state[keys.authority_to_sign]
                                            }
                                          />
                                          <span className="ms-2" />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_1.sub_steps[0].checkbox_fields
                              .signatury_authority.visible ? (
                              <div className="col-12">
                                {this.state[keys.signature_data] &&
                                  this.state[keys.signature_data] !== "" ? (
                                  <img
                                    src={`${APP_LIVE_URL}files_data/profile_signature/${this.state[keys.signature_data]
                                      }`}
                                    height="50px"
                                  />
                                ) : null}
                              </div>
                            ) : null}

                            {this.state[keys.authority_to_sign] ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <Row>
                                    <Col sm={12}>
                                      <div>
                                        <strong>E-Sign:</strong>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row style={{ marginTop: "1%" }}>
                                    <Col sm={12} style={{ height: "120px" }}>
                                      <SignatureCanvas
                                        clearOnResize={true}
                                        canvasProps={{
                                          className: styles.sigPad,
                                        }}
                                        velocityFilterWeight={0.7}
                                        throttle={50}
                                        ref={(ref) => {
                                          this.signaturePad = ref;
                                        }}
                                      />
                                    </Col>
                                    {/*<Col sm={12}
                                              style={{ height: "120px" }}>
                                      <SignatureCanvas
                                        
                                        velocityFilterWeight={0.7}
                                        required
                                        throttle={50}
                                        onEnd={() => {
                                          this.setState({
                                            signature_data: this.signaturePad
                                              .getTrimmedCanvas()
                                              .toDataURL("image/svg+xml"),
                                          });
                                        }}
                                        ref={(ref) => {
                                          this.signaturePad = ref;
                                        }}
                                      />
                                    </Col>*/}
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      <button
                                        className="btn btn-dim btn-light"
                                        style={{
                                          margin: "13px 12px 12px 10px",
                                          float: "right",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.signaturePad.clear();
                                          this.setState({
                                            signature_data: "",
                                          });
                                        }}
                                      >
                                        Clear
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <div className="row mt-5 gx-1 gy-1">
                        {jsonData.step_1.sub_steps[0].button_fields.back
                          .visible && steps_completed > 0 ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_1.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_1.sub_steps[0].button_fields.continue
                          .visible && steps_completed < steps_allowed - 1 ? (
                          <div
                            className={
                              steps_completed === 0 ? "col-lg-12" : "col-lg-6"
                            }
                          >
                            <MyCustomButton
                              data={
                                jsonData.step_1.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                        {/*{post.timeline.map((posts) => (
                          <div>
                            <p>posts.ringId</p>
                            <p>posts.date</p>

                            <p>posts.description</p>
                          </div>
                        ))}*/}
                        {jsonData.step_1.sub_steps[0].button_fields.submit
                          .visible && steps_completed === steps_allowed - 1 ? (
                          <div
                            className={
                              steps_completed === 0 ? "col-lg-12" : "col-lg-6"
                            }
                          >
                            <MyCustomButton
                              data={
                                jsonData.step_1.sub_steps[0].button_fields
                                  .submit
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">                    
                        {jsonData.step_1.sub_steps[0].button_fields.continue
                          .visible ? (
                          <li className="step-next">
                            <MyCustomButton
                              data={
                                jsonData.step_1.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.SubmitFirst}
                              disabled={this.state.disabled}
                            />
                          </li>
                        ) : null}
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step1;
